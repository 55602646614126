import FancyHead from '@/Components/Elements/Links/FancyHead';
import React from 'react';

export default function GuestLayout({ children }: any) {
  return (
    <>
      <FancyHead />
      <div className="min-h-screen flex flex-col sm:justify-center items-center pt-6 sm:pt-0 bg-gray-100">
        {children}
      </div>
    </>
  );
}
